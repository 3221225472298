<template>
  <section class="table_box all_sellers">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Все продавцы
        <p v-if="pagination.from">Результат: {{pagination.from}} - {{pagination.to}} из {{pagination.total}}</p>
        </span>
      </div>
      <div class="wrap_btn flx">
        <div class="btn actions_btn empty" :class="{active: show_dropshipper_filter}" data-id="filters_box"
             @click="toggleDropshippersFilter">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3333 1H1L6.33333 7.30667V11.6667L9 13V7.30667L14.3333 1Z" stroke="#F5A32A"
                  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Фильтры</span>
          <span class="counter_filters" v-if="count_dropshipper_filter > 0">{{ count_dropshipper_filter }}</span>
        </div>
        <router-link class="btn create" to="/dashboard/dropshipping/dropshippers/create">Новый продавец</router-link>
      </div>
      <dropshipper-filter></dropshipper-filter>
    </div>
    <div class="container">
      <div class="table_body table_all_sellers">
        <div class="top_table flx">
          <div class="wrap_search flx">
            <button type="submit"></button>
            <input type="text" v-model="search" placeholder="Поиск..." id="search">
            <ul class="search_list_box">
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
            </ul>
          </div>
          <div class="pagination flx">
            <div class="flx">
              <p>Строк:</p>
              <brew-select
                  class="select-pagination select-dropshipper-per_page"
                  style="margin-left:10px;width: 100px"
                  v-model="perPage"
                  :clearable="false"
                  :options="page_sizes"
              >
              </brew-select>
            </div>
            <paginate
                style="margin-left: 20px"
                class="flx"
                v-model="page"
                :page-count="pagination.last_page"
                :page-range="3"
                :margin-pages="2"
                :prev-text="'<'"
                :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
          <div class="row_list">
            <div
                v-for="dropshipper in dropshippers"
                :key="'dropshipper-'+dropshipper.id"
                class="row flx"
                :class="dropshipper.locked?'block':''"
                :rel="dropshipper.id"
            >
              <div class="id_seller">
                <a href="#">{{ dropshipper.id }}</a>
              </div>
              <div class="login_phone_seller">
                <span v-if="dropshipper.login"><b>{{ dropshipper.login }}</b></span>
                <span v-if="dropshipper.phone">{{ dropshipper.phone | removeCodeCountry }}</span>
                <span v-if="dropshipper.telegram">{{ dropshipper.telegram}}</span>
              </div>
              <div class="full_name_seller">
                <p>{{ dropshipper.name }}</p>
              </div>
              <div class="balance_seller">
                <span :class="getColorBalance(dropshipper.balance)"><b>{{ dropshipper.balance | asPrice }}</b></span>
              </div>
              <div class="orders_seller">
                <span>{{ dropshipper.orders_count }}</span>
              </div>
              <div class="returs_seller">
                <span>{{ dropshipper.returns_count }}</span>
              </div>
              <div class="discount_seller">
                <div class="item flx">
                  <span>Категорий:</span>
                  <span class="line"> </span>
                  <p class="orange"><b>{{ dropshipper.discount_categories_count }}</b></p>
                </div>
                <div class="item flx">
                  <span>Товаров:</span>
                  <span class="line"> </span>
                  <p class="orange"><b>{{ dropshipper.discount_products_count }}</b></p>
                </div>
              </div>
              <div class="active_seller">
                <span><b>{{ dropshipper.last_visited_at | onlyDate }}</b></span>
                <span>{{ dropshipper.last_visited_at | onlyTime }}</span>
              </div>
              <div class="icon_wrap_sel flx">
                  <router-link class="edit_ico" :to="'/dashboard/dropshipping/dropshippers/edit/'+dropshipper.id">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0 3.07755C0 2.05297 0.830588 1.22238 1.85517 1.22238H5.88881C6.2769 1.22238 6.59151 1.53699 6.59151 1.92508C6.59151 2.31317 6.2769 2.62778 5.88881 2.62778H1.85517C1.60677 2.62778 1.4054 2.82915 1.4054 3.07755V11.1448C1.4054 11.3932 1.60677 11.5946 1.85517 11.5946H9.92245C10.1709 11.5946 10.3722 11.3932 10.3722 11.1448V7.11119C10.3722 6.7231 10.6868 6.40849 11.0749 6.40849C11.463 6.40849 11.7776 6.7231 11.7776 7.11119V11.1448C11.7776 12.1694 10.947 13 9.92245 13H1.85517C0.830588 13 0 12.1694 0 11.1448V3.07755Z"
                            fill="#ADB8C6"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.4424 1.55761C11.2394 1.35467 10.9104 1.35467 10.7074 1.55761L5.37082 6.89421L5.12585 7.87412L6.10576 7.62914L11.4424 2.29255C11.6453 2.0896 11.6453 1.76056 11.4424 1.55761ZM9.71365 0.563843C10.4654 -0.187948 11.6843 -0.187948 12.4361 0.563843C13.1879 1.31563 13.1879 2.53453 12.4361 3.28632L6.9619 8.76054C6.87184 8.8506 6.759 8.91449 6.63544 8.94538L4.33051 9.52161C4.09104 9.58148 3.83773 9.51131 3.66319 9.33678C3.48865 9.16224 3.41849 8.90893 3.47835 8.66946L4.05459 6.36452C4.08548 6.24097 4.14937 6.12813 4.23942 6.03807L9.71365 0.563843Z"
                            fill="#ADB8C6"/>
                    </svg>
                  </router-link>
                <span class="lock_open" @click="toggleLockDropshipper(dropshipper)"></span>
                <span class="lock_block" @click="toggleLockDropshipper(dropshipper)"></span>
                <span class="close" @click="deleteDropshipper(dropshipper)"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dropshipper-delete-item-modal></dropshipper-delete-item-modal>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import DropshipperFilter from './DropshipperFilter'
import { prices } from '../../../helpers/prices'
import { dates } from '../../../helpers/dates'
import helper from '@/helpers/helper'
import DropshipperDeleteItemModal from './DropshipperDeleteItemModal'
import { phones } from '../../../helpers/phones'
import Headline from '../../Helpers/Headline'
export default {
  name: 'Dropshippers',
  components: { DropshipperFilter, DropshipperDeleteItemModal, Headline },
  mixins: [
    prices,
    dates,
    phones
  ],
  data () {
    return {
      page_sizes: [10, 50, 100, 1000],
      timer: null,
      headers: [
        {
          title: 'ID',
          sortable: true,
          class: 'id_seller',
          value: 'id'
        },
        {
          title: 'Логин・телефон',
          class: 'login_phone_seller'
        },
        {
          title: 'ФИО',
          class: 'full_name_seller',
          sortable: true,
          value: 'name'
        },
        {
          title: 'Баланс',
          class: 'balance_seller',
          sortable: true,
          value: 'balance_total'
        },
        {
          title: 'Кол-во заказов',
          class: 'orders_seller',
          sortable: true,
          value: 'orders_count'
        },
        {
          title: 'Кол-во возвратов',
          class: 'returs_seller',
          sortable: true,
          value: 'returns_count'
        },
        {
          title: 'Скидки',
          class: 'discount_seller'
        },
        {
          title: 'Последний раз в сети',
          class: 'active_seller',
          sortable: true,
          value: 'last_visited_at'
        },
        {
          title: '',
          class: 'icon_wrap_sel'
        }
      ]
    }
  },
  computed: {
    ...mapState('users', [
      'dropshippers',
      'pagination',
      'dropshipper_filters',
      'show_dropshipper_filter'
    ]),
    ...mapGetters('users', ['count_dropshipper_filter']),
    page: {
      get: function () {
        return this.dropshipper_filters.page
      },
      set: function (value) {
        this.setPage(value)
        this.getDropshippers()
      }
    },
    perPage: {
      'get' () {
        return this.dropshipper_filters.per_page
      },
      'set' (perPage) {
        this.setDropshipperPerPage(perPage)
        this.setPage(1)
        this.getDropshippers()
      }
    },
    search: {
      get: function () {
        return this.dropshipper_filters.search
      },
      set: function (value) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setPage(1)
          this.setSearch(value)
          this.getDropshippers()
        }, 1000)
      }
    },
    sortBy: {
      get () {
        return this.dropshipper_filters.order_by
      },
      set (val) {
        this.setDropshippersOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.dropshipper_filters.order_direction
      },
      set (val) {
        this.setDropshippersOrderDirection(val)
      }
    }
  },
  watch: {
    sortBy () {
      this.getDropshippers()
    },
    sortDesc () {
      this.getDropshippers()
    }
  },
  methods: {
    ...mapActions('users', ['getDropshippers', 'toggleDropshippersFilter', 'updateDropshipperLocked']),
    ...mapMutations('users', ['setDropshipperPerPage', 'setPage', 'setShowDropshipperDeleteModal', 'setDropshipperOnDelete', 'setSearch', 'setDropshippersOrderBy', 'setDropshippersOrderDirection', 'clearDropshipperFilters']),
    getColorBalance (value) {
      return helper.getColorBalance(value)
    },
    toggleLockDropshipper (dropshipper) {
      dropshipper.locked = !dropshipper.locked
      this.$nextTick(() => {
        this.updateDropshipperLocked(dropshipper)
      })
    },
    deleteDropshipper (dropshipper) {
      this.setDropshipperOnDelete(dropshipper)
      this.setShowDropshipperDeleteModal(true)
    }
  },
  created () {
    this.clearDropshipperFilters()
    this.getDropshippers()
  }
}
</script>

<style scoped lang="scss">

</style>
