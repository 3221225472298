import axios from 'axios'
import { eventBus } from '@/main'
const baseUrl = 'api/users/'
const redirectToDropshippers = 'Dropshoppers'
const redirectToUsers = 'Users'
const rules = {
  order: {
    view: false,
    create: false,
    delete: false,
    update: false
  },
  product: {
    view: false,
    create: false,
    delete: false,
    update: false
  }
}
const defaultUser = {
  id: null,
  name: null,
  login: null,
  phone: null,
  password: null,
  password_confirmation: null,
  email: null,
  role: {
    id: 2,
    name: 'Менеджер',
    is_system: false,
    rules: {
      order: {
        view: false,
        create: false,
        delete: false,
        update: false
      },
      product: {
        view: false,
        create: false,
        delete: false,
        update: false
      }
    }
  },
  rules: {
    order: {
      view: false,
      create: false,
      delete: false,
      update: false
    },
    product: {
      view: false,
      create: false,
      delete: false,
      update: false
    }
  }
}
const defaultDropshipper = {
  id: null,
  name: null,
  login: null,
  phone: null,
  password: null,
  password_confirmation: null,
  email: null,
  credit: 0,
  role: {
    id: 3
  }
}

export default {
  namespaced: true,
  state: {
    user: null,
    dropshippers: [],
    pagination: {
      last_page: 1
    },
    show_dropshipper_filter: false,
    dropshipper_filters: {
      per_page: localStorage.getItem('dropshippers-per-page') || 10,
      order_by: localStorage.getItem('dropshippers-order-by') || 'id',
      order_direction: localStorage.getItem('dropshippers-order-direction') || 'asc',
      roles: [3],
      search: null,
      page: 1,
      user_id: null,
      login: null,
      phone: null,
      name: null,
      balance_from: null,
      balance_to: null,
      orders_count_from: null,
      orders_count_to: null,
      returns_count_from: null,
      returns_count_to: null,
      specials_count_from: null,
      specials_count_to: null,
      last_visited_from: null,
      last_visited_to: null,
      sum_from: null,
      sum_to: null,
      operation_id: null
    },
    show_dropshipper_delete_modal: false,
    dropshipper_on_delete: null,
    edit_dropshipper: {
      id: null,
      name: null,
      login: null,
      phone: null,
      password: null,
      telegram: null,
      password_confirmation: null,
      email: null,
      role: {
        id: 3
      }
    },
    show_dropshipper_actions: false,
    creators: [],
    users: [],
    show_user_filter: false,
    user_filters: {
      per_page: localStorage.getItem('users-per-page') || 10,
      order_by: localStorage.getItem('users-order-by') || 'id',
      order_direction: localStorage.getItem('users-order-direction') || 'asc',
      roles: null,
      search: null,
      page: 1,
      user_id: null,
      login: null,
      phone: null,
      name: null,
      last_visited_from: null,
      last_visited_to: null
    },
    show_user_delete_modal: false,
    user_on_delete: null,
    edit_user: {
      id: null,
      name: null,
      login: null,
      phone: null,
      telegram: null,
      password: null,
      password_confirmation: null,
      email: null,
      role: {
        id: 2,
        name: 'Менеджер',
        is_system: false,
        rules: {
          order: {
            view: false,
            create: false,
            delete: false,
            update: false
          },
          product: {
            view: false,
            create: false,
            delete: false,
            update: false
          }
        }
      },
      rules: {
        order: {
          view: false,
          create: false,
          delete: false,
          update: false
        },
        product: {
          view: false,
          create: false,
          delete: false,
          update: false
        }
      }
    }
  },
  getters: {
    count_dropshipper_filter: state => Object.values(state.dropshipper_filters).filter(f => f).length - 5,
    count_user_filter: state => Object.values(state.user_filters).filter(f => f).length - 4,
    getDefaultDropshipperFilters () {
      return {
        per_page: localStorage.getItem('dropshippers-per-page') || 10,
        order_by: localStorage.getItem('dropshippers-order-by') || 'id',
        order_direction: localStorage.getItem('dropshippers-order-direction') || 'asc',
        roles: [3],
        search: null,
        page: 1,
        user_id: null,
        login: null,
        phone: null,
        name: null,
        balance_from: null,
        balance_to: null,
        orders_count_from: null,
        orders_count_to: null,
        returns_count_from: null,
        returns_count_to: null,
        specials_count_from: null,
        specials_count_to: null,
        last_visited_from: null,
        last_visited_to: null,
        sum_from: null,
        sum_to: null,
        operation_id: null
      }
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setUsers (state, users) {
      state.users = users
    },
    setEditUser (state, user) {
      state.edit_user = user
    },
    clearEditUser (state) {
      state.edit_user = defaultUser
    },
    setUserFilters (state, filters) {
      state.user_filters = filters
    },
    clearUserFilters (state) {
      state.user_filters = {
        per_page: localStorage.getItem('users-per-page') || 10,
        order_by: localStorage.getItem('users-order-by') || 'id',
        order_direction: localStorage.getItem('users-order-direction') || 'asc',
        roles: null,
        search: null,
        page: 1,
        user_id: null,
        login: null,
        phone: null,
        name: null,
        last_visited_from: null,
        last_visited_to: null
      }
    },
    setShowUserFilter (state, isTrue) {
      state.show_user_filter = isTrue
    },
    setShowUserDeleteModal (state, isTrue) {
      state.show_user_delete_modal = isTrue
    },
    setUserOnDelete (state, user) {
      state.user_on_delete = user
    },
    setDropshippers (state, data) {
      state.dropshippers = data
    },
    setPagination (state, data) {
      state.pagination = data
    },
    setUserPage (state, page) {
      state.user_filters.page = page
    },
    setUserPerPage (state, perPage) {
      state.user_filters.per_page = perPage
      localStorage.setItem('users-per-page', perPage)
    },
    setOrderBy (state, payload) {
      state.user_filters.order_by = payload
      localStorage.setItem('users-order-by', payload)
    },
    setOrderDirection (state, payload) {
      state.user_filters.order_direction = payload
      localStorage.setItem('users-order-direction', payload)
    },
    setUserSearch (state, Search) {
      state.user_filters.search = Search
    },
    setPage (state, page) {
      state.dropshipper_filters.page = page
    },
    setDropshipperPerPage (state, perPage) {
      state.dropshipper_filters.per_page = perPage
      localStorage.setItem('dropshippers-per-page', perPage)
    },
    setDropshippersOrderBy (state, payload) {
      state.dropshipper_filters.order_by = payload
      localStorage.setItem('dropshippers-order-by', payload)
    },
    setDropshippersOrderDirection (state, payload) {
      state.dropshipper_filters.order_direction = payload
      localStorage.setItem('dropshippers-order-direction', payload)
    },
    setDropshipperFilters (state, filters) {
      this.dropshipper_filters = filters
    },
    clearDropshipperFilters (state) {
      state.dropshipper_filters = {
        per_page: localStorage.getItem('dropshippers-per-page') || 10,
        order_by: localStorage.getItem('dropshippers-order-by') || 'id',
        order_direction: localStorage.getItem('dropshippers-order-direction') || 'asc',
        roles: [3],
        search: null,
        page: 1,
        user_id: null,
        login: null,
        phone: null,
        name: null,
        balance_from: null,
        balance_to: null,
        orders_count_from: null,
        orders_count_to: null,
        returns_count_from: null,
        returns_count_to: null,
        specials_count_from: null,
        specials_count_to: null,
        last_visited_from: null,
        last_visited_to: null,
        sum_from: null,
        sum_to: null,
        operation_id: null
      }
    },
    setShowDropshipperFilter (state, isTrue) {
      state.show_dropshipper_filter = isTrue
    },
    setShowDropshipperDeleteModal (state, isTrue) {
      state.show_dropshipper_delete_modal = isTrue
    },
    setDropshipperOnDelete (state, dropshipper) {
      state.dropshipper_on_delete = dropshipper
    },
    setEditDropshipper (state, data) {
      state.edit_dropshipper = data
    },
    setShowDropshipperActions (state, isTrue) {
      state.show_dropshipper_actions = isTrue
    },
    setCreators (state, creators) {
      state.creators = creators
    },
    setSearch (state, Search) {
      state.dropshipper_filters.search = Search
    },
    setUserRoleModelRules (state, [model, type, value]) {
      if (!state.edit_user.rules) {
        state.edit_user.rules = rules
      }
      if (!state.edit_user.rules[model]) {
        state.edit_user.rules[model] = {
          create: false,
          update: false,
          view: false,
          delete: false
        }
      }
      state.edit_user.rules[model][type] = value
    },
    toggleUserSelectAllRules (state, [model, value]) {
      if (!state.edit_user.rules) {
        state.edit_user.rules = rules
      }
      state.edit_user.rules[model] = {
        create: value,
        update: value,
        view: value,
        delete: value
      }
    }
  },
  actions: {
    getUsers ({ state, commit }) {
      axios.get(baseUrl, {
        params: {
          per_page: state.user_filters.per_page,
          roles: state.user_filters.roles,
          search: state.user_filters.search,
          page: state.user_filters.page,
          user_id: state.user_filters.user_id || null,
          login: state.user_filters.login,
          phone: state.user_filters.phone,
          name: state.user_filters.name,
          last_visited_from: state.user_filters.last_visited_from || null,
          last_visited_to: state.user_filters.last_visited_to || null,
          without_roles: [3],
          order_by: state.user_filters.order_by || null,
          order_direction: state.user_filters.order_direction || null
        }
      }).then(response => {
        commit('setUsers', response.data.data)
        commit('setPagination', response.data.meta)
      })
    },
    getUser ({ state, commit }, id) {
      axios.get(baseUrl + id).then(response => {
        commit('setEditUser', response.data.data)
      }).catch(err => {
        console.log(err)
        commit('setEditUser', defaultUser)
      })
    },
    createUser ({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(baseUrl, state.edit_user).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Пользователь создан',
            'Создан: ' + response.data.data.name
          )
          commit('setEditUser', defaultUser)
          resolve(redirectToUsers)
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Пользователь не создан!',
            'Проверьте данные'
          )
          reject(err)
        })
      })
    },
    deleteUser (action, id) {
      axios.delete(baseUrl + id).then(response => {
        action.commit('setShowUserDeleteModal', false)
        action.commit('setUserOnDelete', null)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Пользователь успешно удален'
        )
        action.dispatch('getUsers')
      }).catch(err => {
        console.log(err)
        action.commit('setShowUserDeleteModal', false)
        action.commit('setUserOnDelete', null)
      })
    },
    updateUser ({ commit, dispatch }, user) {
      axios.put(baseUrl + user.id, {
        login: user.login || null,
        name: user.name || null,
        phone: user.phone || null,
        email: user.email || null,
        password: user.password || null,
        password_confirmation: user.password_confirmation || null,
        locked: user.locked,
        role: user.role,
        rules: user.rules
      })
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные пользователя обновлены'
          )
          commit('setEditUser', response.data.data)
          commit('errors/clearErrors', null, { root: true })
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные пользователя не обновлены!',
            'Проверьте данные'
          )
        })
    },
    updateUserLocked ({ commit, dispatch }, user) {
      axios.put(baseUrl + user.id, {
        locked: user.locked
      })
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные пользователя обновлены'
          )
          dispatch('getUsers')
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные пользователя не обновлены!',
            'Проверьте данные'
          )
        })
    },
    toggleUserFilter ({ state, commit }) {
      commit('setShowUserFilter', !state.show_user_filter)
    },
    getDropshippers ({ state, commit }) {
      axios.get(baseUrl, {
        params: {
          per_page: state.dropshipper_filters.per_page,
          roles: state.dropshipper_filters.roles,
          search: state.dropshipper_filters.search,
          page: state.dropshipper_filters.page,
          user_id: state.dropshipper_filters.user_id || null,
          login: state.dropshipper_filters.login,
          phone: state.dropshipper_filters.phone,
          name: state.dropshipper_filters.name,
          balance_from: state.dropshipper_filters.balance_from || null,
          balance_to: state.dropshipper_filters.balance_to || null,
          orders_count_from: state.dropshipper_filters.orders_count_from || null,
          orders_count_to: state.dropshipper_filters.orders_count_to || null,
          returns_count_from: state.dropshipper_filters.returns_count_from || null,
          returns_count_to: state.dropshipper_filters.returns_count_to || null,
          specials_count_from: state.dropshipper_filters.specials_count_from || null,
          specials_count_to: state.dropshipper_filters.specials_count_to || null,
          last_visited_from: state.dropshipper_filters.last_visited_from || null,
          last_visited_to: state.dropshipper_filters.last_visited_to || null,
          sum_from: state.dropshipper_filters.sum_from || null,
          sum_to: state.dropshipper_filters.sum_to || null,
          operation_id: state.dropshipper_filters.operation_id || null,
          order_by: state.dropshipper_filters.order_by || null,
          order_direction: state.dropshipper_filters.order_direction || null
        }
      }).then(response => {
        commit('setDropshippers', response.data.data)
        commit('setPagination', response.data.meta)
      })
    },
    getDropshippersForSelect ({ state, commit }) {
      axios.get(baseUrl, {
        params: {
          per_page: 15,
          roles: state.dropshipper_filters.roles,
          search: state.dropshipper_filters.search,
          page: state.dropshipper_filters.page,
          user_id: state.dropshipper_filters.user_id || null,
          login: state.dropshipper_filters.login,
          phone: state.dropshipper_filters.phone,
          name: state.dropshipper_filters.name,
          balance_from: state.dropshipper_filters.balance_from || null,
          balance_to: state.dropshipper_filters.balance_to || null,
          orders_count_from: state.dropshipper_filters.orders_count_from || null,
          orders_count_to: state.dropshipper_filters.orders_count_to || null,
          returns_count_from: state.dropshipper_filters.returns_count_from || null,
          returns_count_to: state.dropshipper_filters.returns_count_to || null,
          specials_count_from: state.dropshipper_filters.specials_count_from || null,
          specials_count_to: state.dropshipper_filters.specials_count_to || null,
          last_visited_from: state.dropshipper_filters.last_visited_from || null,
          last_visited_to: state.dropshipper_filters.last_visited_to || null,
          sum_from: state.dropshipper_filters.sum_from || null,
          sum_to: state.dropshipper_filters.sum_to || null,
          operation_id: state.dropshipper_filters.operation_id || null,
          order_by: state.dropshipper_filters.order_by || null,
          order_direction: state.dropshipper_filters.order_direction || null
        }
      }).then(response => {
        commit('setDropshippers', response.data.data)
        commit('setPagination', response.data.meta)
      })
    },
    searchDropshippers ({ state, commit }, search) {
      axios.get(baseUrl, {
        params: {
          per_page: 10,
          search: search,
          page: 1
        }
      }).then(response => {
        commit('setDropshippers', response.data.data)
        commit('setPagination', response.data.meta)
      })
    },
    getDropshipper ({ state, commit, dispatch }, id) {
      axios.get(baseUrl + id).then(response => {
        commit('setEditDropshipper', response.data.data)
        commit('discount/setModelType', null, { root: true })
        dispatch('discount/getDiscountListByUser', response.data.data.id, { root: true })
      }).catch(err => {
        commit('setEditDropshipper', defaultDropshipper)
        console.log(err)
      })
    },
    toggleDropshippersFilter ({ state, commit }) {
      commit('setShowDropshipperFilter', !state.show_dropshipper_filter)
    },
    toggleDropshipperActions ({ state, commit }) {
      commit('setShowDropshipperActions', !state.show_dropshipper_actions)
    },
    updateDropshipperLocked ({ commit, dispatch }, dropshipper) {
      axios.put(baseUrl + dropshipper.id, {
        locked: dropshipper.locked
      })
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные продавца обновлены'
          )
          dispatch('getDropshippers')
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные продавца не обновлены!',
            'Проверьте данные'
          )
        })
    },
    updateDropshipper ({ commit, dispatch }, dropshipper) {
      axios.put(baseUrl + dropshipper.id, {
        login: dropshipper.login || null,
        name: dropshipper.name || null,
        phone: dropshipper.phone || null,
        email: dropshipper.email || null,
        telegram: dropshipper.telegram || null,
        password: dropshipper.password || null,
        password_confirmation: dropshipper.password_confirmation || null,
        locked: dropshipper.locked,
        credit: dropshipper.credit
      })
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные продавца обновлены'
          )
          commit('setEditDropshipper', response.data.data)
          commit('errors/clearErrors', null, { root: true })
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные продавца не обновлены!',
            'Проверьте данные'
          )
        })
    },
    createDropshipper ({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(baseUrl, data).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Продавец создан',
            'Создан: ' + response.data.data.name
          )
          commit('setEditDropshipper', defaultDropshipper)
          resolve(redirectToDropshippers)
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Продавец не создан!',
            'Проверьте данные'
          )
          reject(err)
        })
      })
    },
    deleteDropshipper (action, id) {
      axios.delete(baseUrl + id).then(response => {
        action.commit('setShowDropshipperDeleteModal', false)
        action.commit('setDropshipperOnDelete', null)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Продавец успешно удален'
        )

        action.dispatch('getDropshippers')
      }).catch(err => {
        console.log(err)
        action.commit('setShowDropshipperDeleteModal', false)
        action.commit('setDropshipperOnDelete', null)
      })
    },
    getCreators ({ commit }) {
      axios.get(baseUrl, {
        params: {
          per_page: 100,
          roles: [1, 3],
          page: 1
        }
      })
        .then(res => {
          commit('setCreators', res.data.data)
        })
    }
  }
}
