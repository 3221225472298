<template>
  <section class="table_box new_seller">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Новый продавец</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" href="#" class="back flx">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="store()">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="info_seller_form">
        <div class="top">
          <span class="page_subtitle">Информация о продавце</span>
        </div>
        <div class="middle">
          <div class="row flx">
            <div class="inp_wrap">
              <p>Логин:<span>*</span></p>
              <input type="text" id="login" :class="{required:hasError('login')}" required v-model="dropshipper.login"
                     autocomplete="off">
            </div>
            <div class="inp_wrap">
              <span class="red">Данные для входа в систему</span>
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap">
              <p>Пароль:<span>*</span></p>
              <input :type="password_type" :class="{required:hasError('password')}" required
                     v-model="dropshipper.password" autocomplete="off">
              <span class="show_pass" v-if="!show_password" @click="show_password = !show_password">Показать</span>
              <span class="show_pass" v-else @click="show_password = !show_password">Скрыть</span>
            </div>
            <div class="inp_wrap">
              <p>Повторите пароль:<span>*</span></p>
              <input type="password" :class="{required:hasError('password_confirmation')}" required
                     v-model="dropshipper.password_confirmation" autocomplete="off">
            </div>
          </div>
        </div>
        <div class="bot">
          <div class="row flx">
            <div class="inp_wrap">
              <p>ФИО:<span>*</span></p>
              <input type="text" id="full_name_seller" :class="{required:hasError('name')}" required
                     v-model="dropshipper.name" autocomplete="off">
            </div>
            <div class="inp_wrap">
              <p>Телефон:<span>*</span></p>
              <input type="tel" id="phone" :class="{required:hasError('phone')}" required v-model="dropshipper.phone"
                     v-mask="phone_mask" autocomplete="off">
            </div>
          </div>
          <div class="row flx">
            <p>Email:</p>
            <input type="email" id="email" v-model="dropshipper.email" autocomplete="off">
          </div>
          <div class="row flx">
            <p>Telegram:</p>
            <input type="text" id="telegram" v-model="dropshipper.telegram" autocomplete="off">
          </div>
          <div class="row flx">
            <p>Кредитный лимит:</p>
            <input type="number" id="credit" v-model="dropshipper.credit" autocomplete="off">
            <p><span>*</span>- поля обязательные для заполнения</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'CreateDropshipper',
  directives: {
    mask
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      show_password: false,
      dropshipper: {
        login: null,
        name: null,
        password: null,
        password_confirmation: null,
        telegram: null,
        phone: null,
        email: null,
        credit: 0,
        role: {
          id: 3
        }
      }
    }
  },
  computed: {
    ...mapState('users', ['default_dropshipper', 'edit_dropshipper']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    },
    password_type () {
      return this.show_password ? 'text' : 'password'
    }
  },
  methods: {
    ...mapActions('users', ['createDropshipper']),
    ...mapMutations('users', ['setEditDropshipper']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    store () {
      this.createDropshipper(this.dropshipper)
        .then((path) => {
          this.$router.push('/dashboard/dropshipping/dropshippers')
        })
    }
  },
  mounted () {
    this.clearErrors()
  }
}
</script>

<style scoped>

</style>
